import React from 'react';
import '../scss/main.scss';

export default function Footer() {
    return (
        <footer className="footer">
        <div className="footer__container">
          
            <p className="footer__text">Created by <a href="https://www.linkedin.com/in/oneuniverse/" target="_blank" rel="noreferrer">OneUniverse</a></p>
           <img src="../opauto.png" alt="opauto" />
            <p className="footer__text">© 2023, MyOneUniverse, Optical Automation, LLC, All rights reserved.</p>
        </div>
        </footer>
    );
    }

