import React from "react";

const AlreadyGuessed = () => {

    return (
        <>
        {<div className="already-guessed">Letter already guessed</div>}
        </>
    )
}

export default AlreadyGuessed;